import React from "react"
import "../styles/styles.scss"
import { Helmet } from "react-helmet"


//Import Components
import Header from "../components/header"
import Project from "../components/project"
import Intro from "../components/intro"
import Footer from "../components/footer"



const IndexPage = () => (
  <div>
    <Helmet>
      <title>Eric Lam — User Experience Designer</title>
    </Helmet>
    <Header/>
    <Intro/>
    <Project/>
    <Footer />
  </div>
)

export default IndexPage
