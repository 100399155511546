import React, { useRef } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Img from "gatsby-image"
import Arrow from '../images/arrow.svg'


const scrollToRef = (ref) => ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); 

const Project = () => {
    
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: [frontmatter___order], order: ASC }) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              order
              title
              subheader
              summary
              backgroundcolor
              textcolor
              overview {
                tag
              }
              image {
                childImageSharp {
                  fluid(maxWidth: 1120, quality: 100) {
                      ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);
  const posts = data.allMarkdownRemark.edges;

  return(
      <div>
          <div className = "background background-dark-gray scroll-section">
              <div className = "scroll-container" role="button" tabIndex={0} onClick={executeScroll} onKeyDown={executeScroll}>
                  <div className="scroll-circle" id="scroll-circle"> 
                      <div className="scroll-arrow" id="arrow1">
                      </div>
                      <div className="scroll-arrow2" id="arrow2">
                      </div>
                  </div>
                  <div className = "scroll-text" id="scroll-text"> 
                      View Work
                  </div>
              </div>
          </div>

          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug;
            const subheader = node.frontmatter.subheader;
            const summary = node.frontmatter.summary;
            const order = node.frontmatter.order;
            const image = node.frontmatter.image;
            const overview = node.frontmatter.overview;
            if (order === '1') {
              return (
                <div className = "background-dark-gray" ref={myRef} key={order}>
                  <div className = "home-container project-container">
                      <div className = "home-left">
                        <Img className = "home-image" fluid={image.childImageSharp.fluid} alt={title} />
                      </div>
                      <div className = "home-right" >
                          <div className = "summary-subheader light-gray-text">{subheader}</div>
                          <AniLink className = "summary-header-link white-text" fade to = {node.fields.slug} duration = {0.3}>{title}</AniLink>
                          <div className = "summary-text white-text">
                            <div dangerouslySetInnerHTML={{
                              __html: {summary}.summary
                            }} />
                          </div>
                          <div className = "overview">
                            {overview.map(({ tag }) => {
                              return(
                                <div className = "tag">
                                  {tag}
                                </div>
                              )
                            })}
                          </div>
                          <AniLink className = "button-project" fade to = {node.fields.slug} duration = {0.3}>
                            View Project 
                            <img className = "button-arrow" src={Arrow} alt="Arrow"/>
                          </AniLink>
                      </div>
                  </div>
                </div>
              )
            }
            else if (order > 1){
              return (
                <div className = "background-dark-gray" key={order}>
                  <div className = "home-container project-container">
                      <div className = "home-left">
                        <Img className = "home-image" fluid={image.childImageSharp.fluid} alt={title} />
                      </div>
                      <div className = "home-right" >
                          <div className = "summary-subheader light-gray-text">{subheader}</div>
                          <AniLink className = "summary-header-link" white-text fade to = {node.fields.slug} duration = {0.3}>{title}</AniLink>
                          <div className = "summary-text white-text">
                            <div dangerouslySetInnerHTML={{
                              __html: {summary}.summary
                            }} />
                             <div className = "overview">
                            {overview.map(({ tag }) => {
                              return(
                                <div className = "tag">
                                  {tag}
                                </div>
                              )
                            })}
                          </div>
                          </div>
                          <AniLink className = "button-project" fade to = {node.fields.slug} duration = {0.3}>
                            View Project 
                            <img className = "button-arrow" src={Arrow} alt="Arrow"/>
                          </AniLink>
                      </div>
                  </div>
                </div>
              )
            }
            else {
              return (
                <div/>
              )
            }
          })}
      </div>
  )
}

export default Project
