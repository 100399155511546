import React from 'react'

const Intro = () => {

    return (
        <div>
            <div className = "navbar-spacing"></div>
            <div className = "background background-dark-gray">
                <div className = "home-container-intro">
                    <div className = "home-left intro-left">      
                        <div className = "intro-header white-text">Eric Lam</div>
                        <div className = "summary-text intro-summary white-text"><b>User Experience Designer</b></div>
                        <div className = "summary-text white-text">Passionate in building relationships to translate complex problems into delightful experiences
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Intro;